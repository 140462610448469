import React, { Fragment, useEffect, useState } from "react"
import {
  CloseButton,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalContent,
  ModalDialog,
} from "../Modal"
import CloseIcon from "../Icons/CloseIcon"
import {
  Form,
  FormAction,
  FormCol,
  FormRow,
  FormTitle,
  FormTwoCol,
} from "../Form"
import FormButton from "../button/FormButton"
import axios from "axios"
import { navigate } from "gatsby"
import { FormWrap } from "./styles"

const QuotePopupModal = ({ isVisible, onClose, location }) => {
  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [state, setState] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (
      searchParams.get("utm_source") !== "" &&
      searchParams.get("utm_source") !== null
    ) {
      sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
      sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
      sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
      sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
      sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
    }
  }, [])

  const handleSubmit = e => {
    let utm_source_val = "website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") !== "" &&
      sessionStorage.getItem("utm_source") !== null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }

    e.preventDefault()

    const currentUrl = window.location.href
    const data = {
      source: location.origin,
      form: "Quote Form",
      page: currentUrl,
      first_name: firstname,
      last_name: lastname,
      email: email,
      phone_no: phone,
      state: state,
      zipcode: zipcode,
      comment: message,
      utm_medium: utm_medium_val,
      utm_term: utm_term_val,
      utm_campaign: utm_campaign_val,
      utm_content: utm_content_val,
      lead_source: utm_source_val,
    }
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        document.body.classList.remove("popup-open")
        if (typeof dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "form_submit",
            formName: "website_forms",
            enhanced_conversion_data: {
              email: email,
              phone_number: phone,
            },
          })
        }
        navigate("/thanks")
        if (typeof window !== `undefined`) {
          document.body.classList.remove("modal-open")
        }
      })
      .catch(error => {
        alert(error)
      })
  }

  return (
    <Fragment>
      <Modal className="modal" hidden={!isVisible}>
        <ModalDialog className="modal-dialog modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body">
              <CloseButton className="close" onClick={() => onClose()}>
                <CloseIcon />
              </CloseButton>
              <FormTitle className="form-title">
                Get in touch with our building experts
              </FormTitle>
              <FormWrap onSubmit={handleSubmit}>
                <Form className="form">
                  <FormRow className="form-row">
                    <FormTwoCol className="form-coltwo">
                      <div className="form-group">
                        <input
                          type="text"
                          id="firstname"
                          className="form-control"
                          placeholder="First Name"
                          pattern="^(?!\s*$)[A-Za-z\s]+$"
                          required
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                    </FormTwoCol>
                    <FormTwoCol className="form-coltwo">
                      <div className="form-group">
                        <input
                          type="text"
                          id="lastname"
                          className="form-control"
                          placeholder="Last Name"
                          pattern="^(?!\s*$)[A-Za-z\s]+$"
                          required
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>
                    </FormTwoCol>

                    <FormTwoCol className="form-coltwo">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email ID"
                          required
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </FormTwoCol>
                    <FormTwoCol className="form-coltwo">
                      <div className="form-group">
                        <input
                          type="tel"
                          id="phone_no"
                          className="form-control"
                          placeholder="Phone Number"
                          required
                          pattern="[0-9]{10}"
                          onChange={e => setPhone(e.target.value)}
                        />
                      </div>
                    </FormTwoCol>
                    <FormTwoCol className="form-coltwo">
                      <div className="form-group">
                        <input
                          type="text"
                          id="zip_code"
                          pattern="[A-Za-z0-9]+$"
                          className="form-control"
                          placeholder="Zip Code"
                          required
                          onChange={e => setZipcode(e.target.value)}
                        />
                      </div>
                    </FormTwoCol>
                    <FormTwoCol className="form-coltwo">
                      <div className="form-group form-group-select">
                        <select
                          id="state"
                          className="form-control"
                          onChange={e => setState(e.target.value)}
                          required
                        >
                          <option value="">State</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </div>
                    </FormTwoCol>
                    <FormCol>
                      <div className="form-group">
                        <textarea
                          name="review"
                          rows="3"
                          type="text"
                          id="message"
                          className="form-control"
                          placeholder="Enter Your Message"
                          required
                          onChange={e => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </FormCol>
                  </FormRow>
                  <FormAction className="form-action">
                    <FormButton text="Submit" />
                  </FormAction>
                </Form>
              </FormWrap>
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      <ModalBackdrop hidden={!isVisible} />
    </Fragment>
  )
}

export default QuotePopupModal
